import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '@/views/home/HomeContainer.vue'),
        children:[
            {
                path:'',
				name: '',
				meta: { title: '首页'},
                component: () => import(/* webpackChunkName: "about" */ '@/views/home/HomeView.vue'),
            },
            {
                path:'/about',
				name: 'about',
				meta: { title: '关于我们'},
                component: () => import(/* webpackChunkName: "about" */ '@/views/about/About.vue'),
            },
            {
                path:'/message',
				name: 'message',
				meta: { title: '信息披露'},
                component: () => import(/* webpackChunkName: "about" */ '@/views/message/Message.vue'),
            }
        ]
    },

]

const router = new VueRouter({
    routes
})
// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default router
