import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/reset.css'

// Import BootstrapVue
import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files ( ensure to follow the order)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.config.productionTip = false

// 监听路由变化
router.afterEach((to) => {
  // 根据路由中的meta设置文档标题
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
